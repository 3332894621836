// Here you can add other styles

tr.no-padding {
  td {
    padding: 0 !important;
  }
}

.column-link {
  color: inherit;
  text-decoration: none;
  display: block;
  width: 100%;
  height: 100%;
  padding: 0.5rem 0.5rem;
  display: inline-block;

  &:visited {
    color: inherit;
  }

  &:hover,
  &:active {
    text-decoration: none;
    color: inherit;
  }
}
